console.log("Design Bricks :)")
/**
 * Libraries.
 */
function forEach(array, callback, scope) {
    for (var i = 0; i < array.length; i++) {
        callback.call(scope, i, array[i])
    }
}

const tl = gsap.timeline()

gsap.config({
    nullTargetWarn: false,
    trialWarn: false,
});

/**
 * Key A
 * Open Project
 */
document.addEventListener('keydown', function (event) {
    if (event.key === 'a') {
        window.open('https://www.figma.com/proto/421Xx2ZBV5I7qPMvsONJ7a/Design-Bricks---Portfolio?page-id=0%3A1&type=design&node-id=1-3&viewport=918%2C748%2C0.96&t=xRnb5mbXoij9tdOx-1&scaling=min-zoom&starting-point-node-id=1%3A3&hotspot-hints=0&hide-ui=1', '_blank');
    }
});


/**
 * Marquee Infinite Loop
 * HorizontalLoop
 * 1. Home: Introduction - Only breakpoint > 990px
 * 2. Services: Features
*/

// 1. Home: Introduction
let mm = gsap.matchMedia();


tl.to(".home-introduction-works-list", {
    xPercent: -100,
    x: () => innerWidth,
    ease: "none",
    scrollTrigger: {
        trigger: ".home-introduction",
        start: "top top",
        end: () => innerWidth * 3,
        scrub: true,
        pin: false,
        invalidateOnRefresh: true,
        anticipatePin: 1
    }
});

// 2. Services: Features
const marqueeFeatures = document.querySelector(".marquee");

if (marqueeFeatures) {
    horizontalLoop(".marquee-item", {
        repeat: -1,
        paused: false,
        speed: 0.6
    });
}

/**
 * Timeline Expertise
*/
gsap.timeline({
    scrollTrigger: {
        trigger: ".marquee",
        start: "top top",
        scrub: false
    }
}).from('.js-expertise-animation > div', { opacity: 0, y: 100, stagger: 0.2 })


/**
 * FAQ.
 */
const groups = gsap.utils.toArray(".home-faq-item-question");
const menus = gsap.utils.toArray(".home-faq-question-header");
const menuToggles = groups.map(createAnimation);

menus.forEach(menu => {
    menu.addEventListener("click", () => toggleMenu(menu));
});

function toggleMenu(clickedMenu) {
    menuToggles.forEach(toggleFn => toggleFn(clickedMenu))
}

function createAnimation(element) {
    const menu = element.querySelector(".home-faq-question-header");
    const box = element.querySelector(".home-faq-question-content");

    gsap.set(box, { height: "auto", className: 'home-faq-question-content is-active', });

    const animation = gsap.from(box, {
        className: 'home-faq-question-content',
        height: 0,
        duration: 0,
        ease: "power1.inOut"
    }).reverse();


    return function (clickedMenu) {
        if (clickedMenu === menu) {
            animation.reversed(!animation.reversed());
        } else {
            animation.reverse();
        }
    }
}

/**
 * Price.
 * Button Go to Price
 */
document.querySelectorAll(".js-scrollto-price").forEach(element => {
    element.addEventListener('click', function () {
        gsap.to(window, { duration: 1.5, scrollTo: { y: "#price", offsetY: -50 } });

    });
});